import React from 'react';
import styled from 'styled-components';
import FooterMap from './Map';
import LOGO from '../Assets/LogoBG.png';
import { useState, useEffect } from 'react';
import './Footer.css';
const FooterContainer = styled.footer`
  background-color: #973131;
  padding: 10px 0;
  border-top: 1px solid #e7e7e7;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterText = styled.p`
  margin: 10px 0;
  font-size: 0.9rem;
  color: #fff;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
  padding: 0 20px;
  @media (min-width: 768px) {
    margin: 0;
  }
`;

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            
        }
    }
    , [])
  return (
    <FooterContainer>
      <FooterContent>
        <div
        
        style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            width:'100%',

        }}
        >
        <div
        style={{
            // borderRight:width>800? '1px solid black':'0px solid black',

            // height: '300px',
            // marginLeft: '20px',
            // marginRight: '20px',
            // paddingRight: '20px',
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
        }}
        >
        <img src={LOGO} alt="1-800-SWEEPER" style={{ maxWidth: '80%', margin: '0 auto' }} />
        
        <FooterText>
          © 2024 1-800-SWEEPER. All Rights Reserved. | <Link href="#">Privacy Policy</Link> | <Link href="#">Terms of Use</Link>
        </FooterText>
        </div>
        {/* {width<800 && (<div
        style = {{
            borderRight: '2px solid white',
            height: '300px',
            marginLeft: '20px',
            
        }}
        ></div>)} */}
        {width>800 && (<ul
            style={{
                display:'flex',
                flexDirection:'column',
                // justifyContent:'center',
                // alignItems:'center',
                listStyleType:'none',
                padding:0,
                margin:0,
                width:'50%',
                fontSize:'2.0em',
                color:'white',
                marginRight:10,
                // borderLeft:width>800? '1px solid black':'1px solid black',
            }}
        >
            <li>
            <button className='Button'
            
            onClick={() => window.location.href = '/'} 
            >Home</button>
            </li>
            <li>
            <button className='Button'
            onClick={() => window.location.href = '/Services'}
            >Services</button>
            </li>
            <li>
            <button className='Button'
            onClick={() => window.location.href = '/About'}
            >Contact</button>
            </li>
            <li>
            <button className='Button'></button>
            {/* <p>407-840-7400</p> */}
            </li>
        </ul>
    )}
        </div>



        <MapContainer>
          <FooterMap />
        </MapContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
