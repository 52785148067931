import React from 'react';
import ResponsiveAppBar from './FullMenu';
import Image from './Assets/Bulk Pickup.jpg';
import Footer from './CustomComps/Footer.jsx';
import './service.css';

export default function Bulk() {
    return (
        <div className="App">
            <ResponsiveAppBar />
            <div className="BIGcontainer">
                <h1 className="SERVICETITLE">Bulk Pickup Service</h1>
                <img src={Image} alt="Bulk Pickup" className="image" />
                <p className="paragraph">
                    Efficiently remove large items with our reliable bulk pickup service. Perfect for disposing of furniture, appliances, and more. Fast, hassle-free, and eco-friendly solutions tailored to your needs.
                </p>
                <div className="cardsContainer">
                    <div className="card">
                        <h2 className="subTitle">Why Choose Our Bulk Pickup Service?</h2>
                        <ul className="list">
                            <li>Experienced team for safe and efficient removal</li>
                            <li>Convenient scheduling to fit your timetable</li>
                            <li>Environmentally responsible disposal methods</li>
                            <li>Competitive pricing with no hidden fees</li>
                            <li>Friendly and professional service</li>
                        </ul>
                    </div>
                    <div className="card">
                        <h2 className="subTitle">Items We Pickup:</h2>
                        <ul className="list">
                            <li>Furniture (sofas, tables, chairs, etc.)</li>
                            <li>Appliances (refrigerators, washing machines, etc.)</li>
                            <li>Mattresses and box springs</li>
                            <li>Yard waste and construction debris</li>
                            <li>Electronic waste (TVs, computers, etc.)</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                </div>
                <p className="paragraph">
                    Schedule your bulk pickup today and enjoy a clutter-free space! Contact us for a free consultation and quote.
                </p>
            </div>
            <Footer />
        </div>
    );
}
