import React from 'react';
import ResponsiveAppBar from './FullMenu';
import StormCleanupImage from './Assets/StormCleanUp.jpg'; // Make sure you have an image for storm cleanup
import Footer from './CustomComps/Footer.jsx';
import './service.css';

export default function StormCleanup() {
    return (
        <div className="App">
            <ResponsiveAppBar />
            <div className="BIGcontainer">
                <h1 className="SERVICETITLE">Storm Cleanup Services</h1>
                <img src={StormCleanupImage} alt="Storm Cleanup" className="image" />
                <p className="paragraph">
                    Our storm cleanup services are designed to help you recover from the aftermath of severe weather. We offer debris removal, tree trimming, and other services to help you restore your property to its original condition.
                </p>
                <div className="cardsContainer">
                    <div className="card">
                        <h2 className="subTitle">Why Choose Our Storm Cleanup Services?</h2>
                        <ul className="list">
                            <li>Rapid response to emergency situations</li>
                            <li>Experienced team equipped for large-scale cleanups</li>
                            <li>Comprehensive services including debris removal and tree trimming</li>
                            <li>Safe and environmentally responsible practices</li>
                            <li>Competitive pricing and transparent quotes</li>
                        </ul>
                    </div>
                    <div className="card">
                        <h2 className="subTitle">Our Storm Cleanup Services Include:</h2>
                        <ul className="list">
                            <li>Debris and fallen tree removal</li>
                            <li>Tree and shrub trimming</li>
                            <li>Property damage assessment and repair</li>
                            <li>Water damage cleanup and restoration</li>
                            <li>Yard and landscape restoration</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                </div>
                <p className="paragraph">
                    Don't let storm damage overwhelm you. Contact us today for a prompt and professional storm cleanup service to restore your property to its original condition.
                </p>
            </div>
            <Footer />
        </div>
    );
}
