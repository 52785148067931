import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import './../App.css';
const Typewriter = ({ text, delay, infinite }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex <= text.length) {
      timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);

    } 
    else if (infinite) { // ADD THIS CHECK
    //   setCurrentIndex(0);
      setCurrentText(text);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return <Typography 
  sx={{ 
    fontFamily: 'monospace', 
    fontSize: '1.5rem', 
    textAlign: 'center', 
    marginTop: '20px' 
  
  }}
  >{currentText}</Typography>;
};

export default Typewriter;