import React from 'react';
import { Container, Typography} from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import Aside from './Assets/aside.jpg';

const LandingPageContainer = styled(Container)({
  padding: '40px',
  textAlign: 'center',
});

const Title = styled(Typography)({
  marginBottom: '20px',
  fontWeight: 'bold',
});

const Subtitle = styled(Typography)({
  marginBottom: '20px',
});

// const ServicesTitle = styled(Typography)({
//   marginTop: '40px',
//   marginBottom: '20px',
//   fontWeight: 'bold',
// });

const LandingPage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      
    }
  }, [])
  return (
    <LandingPageContainer>
      <div 
      
      style={{display:'flex', flexDirection:'row',gap:30,}}>
        <div
          style={{height:width<800?0:600, width:width<800?0:'50%', backgroundColor:'red', borderRadius:10, 
            backgroundImage:`url(${Aside})`,
            backgroundSize:'cover',
          }}
        >

        </div>
        <div
        style={{
          width:width>800?'50%':'100%'
        }}
        >
          <Title variant="h3">Cluttered Property? Running Out of Time?</Title>
      <Subtitle variant="h5">We've Got You Covered!</Subtitle>

      <p
      style={{
        textAlign:'left',
        fontSize: '1.2rem',
        color: '#333',
        lineHeight: '1.5',
      
      }}
      >
      At Clean Sweep Orlando, we pride ourselves on being the most reliable, knowledgeable, and prompt sweeping service provider in the area. Our services include power sweeping, porter and janitorial services, pressure washing, general maintenance, and landscape maintenance. We understand the importance of a clean and safe environment for your property and are dedicated to providing top-notch, budget-friendly services. Our team of professionals ensures your satisfaction with every job, offering long-lasting solutions that are both eco-friendly and aesthetically pleasing. Trust our years of expertise to keep your property in pristine condition. Contact us today for a free quote and experience the difference we can make!
      </p></div>
      </div>
      
    </LandingPageContainer>
  );
};

export default LandingPage;
