import './App.css';
import Footer from './CustomComps/Footer.jsx';
// import Menu from './Menu';
import ResponsiveAppBar from './FullMenu';
import MediaCard from './CustomComps/Card';
import Image from './Assets/Logo.jpeg';
import cleaning from './Assets/cleaning.jpg';
import Sweep from './Assets/sweep.jpg';
// import PowerWashing from './Assets/PowerWashing.jpg';
import StormCleanup from './Assets/StormCleanUp.jpg';
import { useState,useEffect } from 'react';
import { Button } from '@mui/material';
import LandingPage from './LandingPage';
import React from 'react';
import { Box } from '@mui/material';
import Line from './CustomComps/Line';
import Typewriter from './CustomComps/Typing';
import {useRef}  from 'react';
function Cards(){
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      
    }
  }, []);

  return(
    <div style = {{borderStyle:'solid', borderWidth:0, borderColor:'black', borderRadius:10, minWidth:400,
    alignContent:'center', display:'flex', flexDirection:'column', gap:40, justifyContent:'center',
    width:'100%', padding:20, 
    // backgroundColor:'lightyellow'
    }}>
      <h1 style={{textAlign:'center', color:'#973131'}}>Our Services</h1>
    <div style={{display:'flex',  alignItems:'center', justifyContent:'center',
      gap: '2.5em', flexDirection:width < 1150? 'column':'row',}}>
      {/* SWEEPING, repair and power washing, bulk pickup | Storm cleanup */} 
      <MediaCard image={cleaning}     name="Power Washing" Page="/PowerWashing" Description="With our power washing services we deliver pristine results for your home or business. Revitalize your property with our expert cleaning, leaving surfaces spotless and looking like new."/>
      <MediaCard image={Image}        name="Bulk Pickup"   Page="/BulkPickup"   Description="Efficiently remove large items with our reliable bulk pickup service. Perfect for disposing of furniture, appliances, and more. Fast, hassle-free, and eco-friendly solutions tailored to your needs."/>
      <MediaCard image={StormCleanup} name="Storm Cleanup" Page="/StormCleanup" Description="Our storm cleanup services are designed to help you recover from the aftermath of severe weather. We offer debris removal, tree trimming, and other services to help you restore your property to its original condition."/>
      <MediaCard image={Sweep} name="Sweeping"             Page="/Sweeping"     Description="Comprehensive sweeping solutions for industrial, commercial, and residential properties. Keep your spaces clean and safe with our reliable, efficient, and thorough sweeping services tailored to meet all your needs."/>
    </div>
    <Button variant="contained" 
            style={{backgroundColor:'white', color:'#973131', width:200, height:40, 
            borderRadius:10, alignSelf:'center', position:'relative', 
            }}
              onClick={() => window.location.href = '/Services'}
            >View All Services</Button>
                    
      {/* <Button variant="text" style={{width:200, height:50, color:'black', borderRadius:10, alignSelf:'center'}}>View All Services</Button> */}
    </div>
  )
}



// export default Footer;

const Banner = () => {
  return (
    <div style = {{width:'100%', display:'flex', justifyContent:'center',alignItems:'center',position:'relative', flexDirection:'column',

      backgroundColor: 'transparent', padding: '20px',gap:50
    }}>
      
      <h1>
        Want to schedule a service?
      </h1>

      <Line Array={[1,2,3,4]} success={'#973131'} failure = {'lightgrey'}/>
      
    </div>
  );
}

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [state, setState] = React.useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      
    }
  }, [])
  return (
    <div style={{minWidth:'100%', displayl:'flex', flexDirection:'column'}}>
      <ResponsiveAppBar />
      <div
       className='background-image'
      
      >
        <div className='title'>

          <h1 className='title-text'> Clean Sweep Orlando</h1>
          <Typewriter text="Keeping America’s Properties Pristine, One Sweep at a Time." delay={50} infinite />
          <Box sx={{display:'flex', flexDirection:width<800?'column':'row', gap:'0.5em', paddingTop:10,
            justifyContent:'center', alignItems:'center',

          }}>

            <Button variant="contained" 
            style={{backgroundColor:'#973131', color:'white', width:200, height:40,
            borderRadius:10, alignSelf:'center', position:'relative', }}
            onClick={() => setState(!state)}
            >Get a Quote</Button>
            <Button variant="contained" onClick={() => window.location.href = '/contact'}
            style={{backgroundColor:'white', color:'#973131', width:200, height:40, 
            borderRadius:10, alignSelf:'center', position:'relative', }}>Contact Us</Button>
            
          </Box>
          
        </div>
      </div>
      <div
      style={{minWidth:'100%', backgroundColor:'#f2f2f2',  paddingTop:70, display:'flex', flexDirection:'column',
         paddingBottom:70
      }}>
        
       <LandingPage />
       <div
       style={{display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center', width:'100%',
        gap:50
       }}
       >
        <Cards />
        {width>800 &&<Banner/>}
        
        </div>
      </div>

      <Footer />
      
    </div>
  );
}

export default App;
