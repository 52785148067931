import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './About';
import Contact from './Contact';
import reportWebVitals from './reportWebVitals';
import PowerWashing from './PowerWashing';
import Bulk from './Bulk';
import StormCleanup from './StormCleanup';
import Sweeping from './Sweeping';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/services",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/home",
    element: <App />,
  },
  {
    path: "/PowerWashing",
    element: <PowerWashing />,
  },
  {
    path: "/BulkPickup",
    element: <Bulk />,
  },
  {
    path: "/StormCleanup",
    element: <StormCleanup />,
  },
  {
    path: "/Sweeping",
    element: <Sweeping />,
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
