import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './../App.css';
export default function MediaCard({image, name, Description,Page}) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
  return (
    <Card sx={{ width: 345, transition: '0.3s',height:350, backgroundColor:'#973131'}}
    
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={()=>{
      if(Page !==""){
        navigate(Page);
      }
    }}
    >
      <CardMedia
      className='cardMedia'
        sx={{ height:isHovered ? 180 : 280 ,transition: '0.5s' }}
        image={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{textAlign:'center',color:'white' }}>
          {name}
        </Typography>
        {isHovered&&
        <Typography variant="body2" color="text.secondary" style={{color:'white'}}>
          {Description}
        </Typography>}
      </CardContent>
      <CardActions>

      </CardActions>
    </Card>
  );
}
