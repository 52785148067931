import './App.css';
import React from 'react';
import ResponsiveAppBar from './FullMenu';
import GalleryComp from './CustomComps/Gallery';
import Footer from './CustomComps/Footer.jsx';
import Image from './Assets/Logo.jpeg';
import cleaning from './Assets/cleaning.jpg';
import StormCleanup from './Assets/StormCleanUp.jpg';
import Pump from './Assets/Pump Repair.jpg';
import Bulk from './Assets/Bulk Pickup.jpg';
import Sweep from './Assets/sweep.jpg';
const MainImages=[
  // <MediaCard image={cleaning} name="Power Washing" Description="With our power washing services we deliver pristine results for your home or business. Revitalize your property with our expert cleaning, leaving surfaces spotless and looking like new"/>
  //     <MediaCard image={Image} name="Bulk Pickup" Description="Efficiently remove large items with our reliable bulk pickup service. Perfect for disposing of furniture, appliances, and more. Fast, hassle-free, and eco-friendly solutions tailored to your needs."/>
  //     <MediaCard image={StormCleanup} name="Repairs" Description="Our team quickly clears affected areas, ensuring minimal disruption and helping communities recover swiftly from storm damage."/>
  //     <MediaCard image={StormCleanup} name="Sweeping" Description="Comprehensive sweeping solutions for industrial, commercial, and residential properties. Keep your spaces clean and safe with our reliable, efficient, and thorough sweeping services tailored to meet all your needs."/>
  //     <MediaCard image={Pump} name="Pump Repair" Description="Comprehensive sweeping solutions for industrial, commercial, and residential properties. Keep your spaces clean and safe with our reliable, efficient, and thorough sweeping services tailored to meet all your needs."/>

  {
    src:cleaning,
    name:"Power Washing",
    Description:"With our power washing services we deliver pristine results for your home or business. Revitalize your property with our expert cleaning, leaving surfaces spotless and looking like new",    
    Page:"/PowerWashing"
  },
  {
    src:Bulk,
    name:"Bulk Pickup",
    Description:"Efficiently remove large items with our reliable bulk pickup service. Perfect for disposing of furniture, appliances, and more. Fast, hassle-free, and eco-friendly solutions tailored to your needs.",
    Page:"/BulkPickup"
  
  },
  {
    src:StormCleanup,
    name:"Storm Cleanup",
    Description:"Our team quickly clears affected areas, ensuring minimal disruption and helping communities recover swiftly from storm damage.",
    Page:"/StormCleanup"
  
  },
  {
    src:Sweep,
    name:"Sweeping",
    Description:"Comprehensive sweeping solutions for industrial, commercial, and residential properties. Keep your spaces clean and safe with our reliable, efficient, and thorough sweeping services tailored to meet all your needs.",
    Page:"/Sweeping"
  
  },
  
];
const images = [
  {
    src:Pump,
    name:"Pump Repair",
    Description:"Comprehensive sweeping solutions for industrial, commercial, and residential properties. Keep your spaces clean and safe with our reliable, efficient, and thorough sweeping services tailored to meet all your needs."
    ,Page:""
    
  },
  {
     src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
     name: "After Rain",
     Description: "After Rain (Jeshu John - designerspics.com)",
    Page:""

  },
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     name: "After Rain",
     Description: "After Rain (Jeshu John - designerspics.com)",
    Page:""

  },
  {
     src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
     name: "After Rain",
     Description: "After Rain (Jeshu John - designerspics.com)",
    Page:""

  },
  {
     src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
     name: "After Rain",
     Description: "After Rain (Jeshu John - designerspics.com)",
    Page:""

  },
  
];

function About() {

  return (
    <div className="App">
      <ResponsiveAppBar />
      <div style={{ height:'fit-content',minWidth:'100%', backgroundColor:'#F2F2F2', paddingTop:70}}>
        

        <h1 style={{textAlign:'center', color:'#973131'}}>Our Services</h1>
        <GalleryComp images={MainImages}/>

        <h1 style={{textAlign:'center', color:'#973131',}}>Other Services</h1>
        <GalleryComp images={images}/>
      
      </div>
      <Footer />
    </div>
  );
}

export default About;
