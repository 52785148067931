import React from 'react';
import ResponsiveAppBar from './FullMenu';
import PowerWash from './Assets/PowerWashing.jpg';
import Footer from './CustomComps/Footer.jsx';
import './service.css';
export default function PowerWashing() {
    return (
        <div className="App">
            <ResponsiveAppBar />
            <div className='BIGcontainer' >
                <h1 className='SERVICETITLE'>Power Washing Services</h1>

                <img src={PowerWash} alt="Power Washing" className = 'image'  />
                <p className='paragraph' >
                    Transform your property with our professional power washing services. Whether it's your home's exterior, driveway, patio, or business premises, our expert cleaning team delivers exceptional results.
                </p>
                <div className='cardsContainer'>
                    <div className='card'>
                        <h2 className = 'subTitle' >Why Choose Us?</h2>
                        <ul className = 'list' >
                            <li>Experienced team dedicated to quality cleaning</li>
                            <li>State-of-the-art equipment for effective cleaning</li>
                            <li>Customized cleaning solutions tailored to your needs</li>
                            <li>Affordable pricing with no hidden costs</li>
                            <li>Environmentally friendly cleaning products</li>
                        </ul>
                    </div>

                    <div className='card'>
                        <h2 className = 'subTitle'>Our Services Include:</h2>
                        <ul className = 'list' >
                            <li>Exterior house washing</li>
                            <li>Driveway and sidewalk cleaning</li>
                            <li>Deck and patio cleaning</li>
                            <li>Commercial building exterior cleaning</li>
                            <li>Graffiti and stain removal</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                </div>
                <p className= 'paragraph' >
                    Revitalize your property today with our reliable power washing services. Contact us for a free consultation and quote!
                </p>
            </div>
            <Footer />
        </div>
    );
}

