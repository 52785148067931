import './App.css';
// import Menu from './Menu';
import ResponsiveAppBar from './FullMenu';
import React,{useRef} from 'react';
import Footer from './CustomComps/Footer.jsx';
import emailjs from '@emailjs/browser';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from 'mdb-react-ui-kit';
export function ContactForm() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_iwhbjnb', 'template_wdrlkxd', form.current, {
        publicKey: '8oC2mQnDUs64IJ5rf',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <form ref={form} onSubmit={sendEmail}>
      <MDBRow className='mb-4'>
        <MDBCol>
          <MDBInput id='form6Example1' name='first_name' label='First name' />
        </MDBCol>
        <MDBCol>
          <MDBInput id='form6Example2' name='last_name' label='Last name' />
        </MDBCol>
      </MDBRow>
      <MDBInput wrapperClass='mb-4' id='form6Example3' name='address' label='Address' />
      <MDBInput wrapperClass='mb-4' type='email' id='form6Example5' name='email' label='Email' />
      <MDBInput wrapperClass='mb-4' type='tel' id='form6Example6' name='phone' label='Phone' />
      <MDBInput wrapperClass='mb-4' textarea id='form6Example7' name='additional_info' rows={4} label='Additional information' />
      <MDBBtn className='mb-4' type='submit' block>
        Send Message
      </MDBBtn>
    </form>
  );
}
function Contact() {
  return (
    <div
    style={{minWidth:'100%', display:'flex', flexDirection:'column'}}
    > 



      <div style={{ height:'100svh',minWidth:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center',}}>
        <ResponsiveAppBar />
        <div
          style={{padding:'20px'}}
        >
        <ContactForm />
        </div>
        
    </div>
    <Footer />
    </div>
  );
}

export default Contact;
