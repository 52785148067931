import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button,Box } from '@mui/material';
import './Line.css'; // Custom CSS for additional styling

const Carousel = ({ images, Index }) => {
  const sliderRef = useRef(null);
  const SliderInfo = [
    "Book an appointment with us. Schedule your service easily online or give us a call.",
    "Our team of seasoned professionals is dedicated to delivering exceptional results. Get to know the experts behind our top-notch services!",
    "Reach out today for a no-obligation quote from our experts. Discover how affordable our top-quality services can be.",
    "Sit back and relax! Let us handle the cleaning while you enjoy a pristine property."
  ];
//   const [currentSlide, setCurrentSlide] = React.useState(0);


  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(Index);
    }
  }, [Index]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable navigation arrows
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <div style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            gap:20,
            height:'100%',
              
          }}>
            <img src={image} alt={`slide-${index}`} 
            style={{
              width:'50%',
              height:'300px',
              objectFit:'cover',
              borderRadius: '20px',
              mixBlendMode: 'color-burn',
              // backgroundColor: 'lightblue',
              
            }}
            />
            <div
            style={{width:'45%', height:'300px'}}
            >
            <h2
              style={{
                textAlign:'start',
              }}
              >{SliderInfo[index]}</h2>
              {index ===0 && 
              (
                <Box sx={{display:'flex', flexDirection:'row', gap:'0.5em', 
                  justifyContent:'space-between', alignItems:'center', height: '220px'
    
                }}>
                    <Button variant="contained" 
                    style={{backgroundColor:'#973131', color:'white', width:200, minHeight:40,
                    borderRadius:10, alignSelf:'center', position:'relative', }}>Get a Quote</Button>
                    <Button variant="contained" onClick={() => window.location.href = '/contact'}
                    style={{backgroundColor:'white', color:'#973131', width:200, minHeight:40, 
                    borderRadius:10, alignSelf:'center', position:'relative', }}>Contact Us</Button>
                </Box>
              )}
              <Box sx={{display:'flex', flexDirection:'row', gap:'0.5em', 
                  justifyContent:'space-between', alignItems:'center', height: '220px'
    
                }}>
              {
                index === 3 &&
                (
                  <Button variant="contained" 
                    style={{backgroundColor:'#973131', color:'white', width:200, minHeight:40,
                    borderRadius:10, alignSelf:'center', position:'relative', }}
                    onClick={() => {
                      window.location.href = '/contact';
                    
                    }}
                    >Get Started</Button>
                    
                )}
                
              
              </Box>
              
          </div>
          </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
