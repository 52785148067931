import React from 'react';
import ResponsiveAppBar from './FullMenu';
import SweepingImage from './Assets/sweep.jpg'; // Make sure you have an image for sweeping
import Footer from './CustomComps/Footer.jsx';
import './service.css';

export default function Sweeping() {
    return (
        <div className="App">
            <ResponsiveAppBar />
            <div className="BIGcontainer">
                <h1 className="SERVICETITLE">Sweeping Services</h1>
                <img src={SweepingImage} alt="Sweeping" className="image" />
                <p className="paragraph">
                    Our sweeping services are designed to keep your property clean and free of debris. We offer regular sweeping services to help maintain the appearance of your property and keep it looking its best. Our team uses state-of-the-art equipment to deliver superior results, ensuring that your property is clean and well-maintained.
                </p>
                <div className="cardsContainer">
                    <div className="card">
                        <h2 className="subTitle">Why Choose Our Sweeping Services?</h2>
                        <ul className="list">
                            <li>Regular sweeping schedules to suit your needs</li>
                            <li>Advanced equipment for thorough cleaning</li>
                            <li>Experienced and reliable team</li>
                            <li>Flexible and customized service plans</li>
                            <li>Competitive pricing</li>
                        </ul>
                    </div>
                    <div className="card">
                        <h2 className="subTitle">Our Sweeping Services Include:</h2>
                        <ul className="list">
                            <li>Parking lot sweeping</li>
                            <li>Driveway sweeping</li>
                            <li>Street sweeping</li>
                            <li>Construction site sweeping</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                </div>
                <p className="paragraph">
                    Whether you need sweeping services for a parking lot, driveway, or other area, we can help. Contact us today to learn more about our sweeping services and how we can help you keep your property clean and beautiful.
                </p>
            </div>
            <Footer />
        </div>
    );
}
