import React, { useState } from 'react';
import './Line.css';
import zIndex from '@mui/material/styles/zIndex';
import { Typography } from '@mui/material';
import Carousel from './Carousel';
import Image from '../Assets/Logo.jpeg';
import Apt from '../Assets/Apt.jpg';
import Expert from '../Assets/Expert.jpg';
import Relax from '../Assets/relax.jpeg';
import Quote from '../Assets/Quote.png';
const Line = ({Array, success, failure}) => {
    const [activeButtons, setActiveButtons] = useState([true, false, false, false]);
    let [HighestActiveButton, setHighest] = React.useState(0);

    const Titles = ['Schedule An Appointment', 'Meet Our Experts', 'Get A Quote', 'Relax!'];

    const handleClick = (index) => {

        const newActiveButtons = activeButtons.map((_, i) => i <= index);
        setActiveButtons(newActiveButtons);
        const highestIndex = newActiveButtons.lastIndexOf(true);
        setHighest(highestIndex);
        console.log(highestIndex);
    };
    

    return (
        <div 
        style={{
            width: '70%',
            height: '100%',
            borderRadius: '20px',
            position: 'relative',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems:'space-between',
                width: '100%',
                height: '10px',
                borderRadius: '10px',
                backgroundColor: 'transparent',
            }}>
                {Array.map((_, index) => (
                    <div
                    style={
                        {
                            height:150,
                            width:10,
                            // backgroundColor: 'blue',
                            position: 'absolute',
                            top: '-15px',
                            left: `${11 + index * 25}%`,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap:10
                        }
                    }
                    >

                    <button
                        key={index}
                        style={{
                            borderRadius: '50%',
                            textAlign: 'center',
                            aspectRatio: 1,
                            height: '40px',
                            backgroundColor:activeButtons[index] ? success : failure,
                            
                        }}
                        onClick={() => handleClick(index)}
                    >
                        {index + 1}
                    </button>
                        <Typography variant='h5' style={{color:'black', fontWeight:'bold',textAlign:'center',
                            width:'150px'
                        }}>{Titles[index]}</Typography>
                    </div>
                ))}
                {activeButtons.map((active, index) => (
                    <div
                        key={index}
                        style={{
                            width: '25%',
                            backgroundColor: active ? success : failure,
                            transition: '0.6s linear',
                            borderTopLeftRadius: index === 0 ? '20px' : 0,
                            borderBottomLeftRadius: index === 0 ? '20px' : 0,
                            borderTopRightRadius: index === 3 ? '20px' : 0,
                            borderBottomRightRadius: index === 3 ? '20px' : 0,
                        }}
                    ></div>
                ))}
                
            </div>
            <div style={{
                position:'relative',
                width: '100%',
                minHeight: '100px',
                // backgroundColor: '#0000ff',
                marginTop:'170px'
            }}>
                <Carousel images={[Apt,Expert,Quote,Relax]} Index = {HighestActiveButton} />
            </div>
        </div>
    );
};

export default Line;
