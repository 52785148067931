import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const FooterMap = () => {
  const serviceAreas = [
    { name: 'Tampa', position: [27.9506, -82.4572] },
    { name: 'St. Petersburg', position: [27.7676, -82.6403] },
    { name: 'Clearwater', position: [27.9659, -82.8001] },
    { name: 'Brandon', position: [27.9378, -82.2859] },
    { name: 'Lakeland', position: [28.0395, -81.9498] },
  ];

  return (
    <MapContainer
      center={[27.9506, -82.4572]}
      zoom={9}
      style={{ height: '300px', width: '100%' }}
      dragging={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      keyboard={false}
      touchZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {serviceAreas.map((area, index) => (
        <Marker key={index} position={area.position}>
          <Popup>{area.name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default FooterMap;
