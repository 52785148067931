import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LOGO from './Assets/LogoBG.png';
import { useNavigate } from 'react-router-dom';
const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact'];

function DrawerAppBar(props) {
    const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={LOGO} alt = "" style={{height:50}}></img>
      </Typography>
      <Divider />
      <List>
      <ListItem key= {0} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Home'} onClick={()=>{navigate("/")}}/>
            </ListItemButton>
          </ListItem>
          <ListItem key= {1} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Services'} onClick={()=>{navigate("/services")}}/>
            </ListItemButton>
          </ListItem>
          <ListItem key= {2} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Contact'} onClick={()=>{navigate("/contact")}}/>
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex',}}>
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor:'#973131'}}>
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div style={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'row'}}>
                <MenuIcon />
                <img src={LOGO} alt = "" style={{height:40}}></img>
            </div>
          </IconButton>
          
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
        <img src={LOGO} alt = "" style={{height:50, position:'relative', top:4}}></img>

          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button key={0} sx={{ color: '#fff' }} onClick={()=>{navigate('/')}}>
                {"Home"}
          </Button>
          <Button key={0} sx={{ color: '#fff' }} onClick={()=>{navigate('/services')}}>
                {"Services"}
          </Button>
          <Button key={0} sx={{ color: '#fff' }} onClick={()=>{navigate('/contact')}}>
                {"Contact"}
          </Button>
            
          </Box>
          
        </Toolbar>

      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      
    </Box>
  );
}

export default DrawerAppBar;
