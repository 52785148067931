import MediaCard from "./Card";
import React from "react";
import "./Gallery.css";
import Image from '../Assets/Logo.jpeg';
import cleaning from '../Assets/cleaning.jpg';
import StormCleanup from '../Assets/StormCleanUp.jpg';
import Pump from '../Assets/Pump Repair.jpg';


function GalleryComp({images}){
   return (
      
         <div
            style={{
               display: "grid",
               gap: "2rem",
               padding: "2rem",
               justifyContent: "center",
               columnGap: '30px',
               rowGap: "30px",
               width:'100%',
            }}
            className="grid-container" 
         >
         
      
         {images.map((image, index) => (
            <MediaCard key={index} image={image.src}
               name={image.name}
               Description={image.Description}
               Page={image.Page}
            />
         ))}

      </div>
   );
};

export default GalleryComp;